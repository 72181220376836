import React from 'react'
import PageWrapper from "../components/PageWrapper";
import Related from "../sections/landing/popular.js";
import Find from '../sections/nose/nosefind.js';
import NoseContent from '../sections/removal/removalcontent.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Hero from "../sections/removal/removalhero.js";
import Glance from "../sections/removal/removalglance.js";
import Skins from "../sections/removal/removalskins";
import Testimonial from "../sections/index/indexreviews.js";
import Facts from "../sections/removal/removalfacts.js";
import FAQ from "../sections/removal/removalfaq.js";
import Saveface from "../sections/landing/saveface";

import NoseBlog from "../sections/nose/noseblog.js";
import Cost from "../sections/removal/removalcost.js";
import Did from "../sections/removal/removaldid.js";
import Anatomy from "../sections/removal/removalbody.js";
import How from "../sections/removal/removalhow.js";

const LaserRemovalPage = () => ( 
  <>
 <Head title="Laser Hair Removal in London" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. " keywords="Skinpen® Microneedling, Microneedling, Microneedling London, Microneedling harley street, microneedling near me, microneedling UK"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
      <Hero />
      <Glance />
     
      <NoseContent />
  
      <Testimonial />
      <Skins />
      <Cost />
      <Facts />
      <Did />
     
      <Anatomy />
      <How />
      <FAQ />
      <Related />
      <Find />
      <Saveface />
      
 
     </PageWrapper>
 
  </>
)
export default LaserRemovalPage
